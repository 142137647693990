import React from 'react';
import './refund.css';

const RefundPolicy = () => {
  return (
    <div className="privacy-policy">
      <section className="rc-banner-wrapper">
        <div className="privacy-banner">
          <img
            className="data-cloud-migrator-label4 data-cloud-migrator-label2 "
            loading="lazy"
            alt="refund policy banner"
            src="/privacy_policy/rc-policy.png"
          />
          <button className="bio-s-d-l-c-tel-label">
            <div className="our-commitment-to">
              Understanding Our Refund & Cancellation Policy
            </div>
          </button>
          <h1 className="privacy-policy-for-container">
            <p className="privacy-policy1">Refund and Cancellation Policy</p>
            <p className="for-vaidhyamegha-private">
              for VaidhyaMegha Private Limited
            </p>
          </h1>
        </div>
      </section>
      
      <section className="privacy-policy-inner">
        <div className="privacy-frame-container">
          <div className="privacy-frame-div">
            <h2>Refund Policy</h2>
            <p><strong>Last Updated:</strong> December 24, 2024</p>
            
            <h3>Table of Contents</h3>
            <ol>
              <li>Cancellation Policy</li>
              <li>Refund Eligibility</li>
              <li>Refund Process</li>
              <li>Exclusions</li>
              <li>Policy Changes</li>
              <li>Contact Information</li>
            </ol>

            <h3>1. Cancellation Policy</h3>
            <h4>1.1 Subscription-Based Services</h4>
            <p>You may cancel your subscription-based services at any time. Following cancellation, you will retain access to the service until the end of the current paid billing period. No subsequent charges will be billed. To cancel your subscription, please use the options available in your account settings or contact our customer support team for guidance.</p>
            
            <h4>1.2 Perpetual License Products</h4>
            <p>Products purchased under a perpetual license grant ongoing access and are not subject to cancellation once activated. However, under certain circumstances outlined below, refunds may still be possible. If a refund is granted, access to the product will be deactivated immediately upon the cancellation of the license.</p>
            
            <h3>2. Refund Eligibility</h3>
            <p>Refunds may be considered under the following conditions:</p>
            <ul>
              <li><strong>Technical Issues:</strong> If you encounter significant technical problems or compatibility issues that prevent the normal functioning of our product or service, provided that our support team has had a reasonable opportunity to resolve these issues without success</li>
              <li><strong>Dissatisfaction:</strong> If you are dissatisfied with the functionality or performance of our product or service and have provided specific feedback to our support team</li>
              <li><strong>Unauthorized Charges:</strong> If you notice unauthorized transactions on your account resulting from purchases made through our website</li>
            </ul>
            
            <h3>3. Refund Process</h3>
            <p>Please follow these steps to request a refund:</p>
            <ol>
              <li><strong>Contact Customer Support:</strong> Submit a detailed refund request to our support team, including your order number, purchase date, and the specific reasons for the refund request</li>
              <li><strong>Evaluation:</strong> Our support team will review your request based on the eligibility criteria mentioned above.</li>
              <li><strong>Refund Issuance:</strong> Approved refunds will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days (typically 7 to 14 business days).</li>
            </ol>
            
            <h3>4. Exclusions</h3>
            <p>The following situations are not eligible for refunds:</p>
            <ul>
              <li><strong>Change of Mind:</strong> We do not grant refunds if you change your mind about using our software or services but the product is performing as advertised</li>
              <li><strong>Third-Party Products/Services:</strong> Refunds for third-party products or services purchased through our platform are not offered. Please contact the respective third party directly for their refund policies</li>
            </ul>
            
            <h3>5. Changes to Refund Policy</h3>
            <p>We reserve the right to update or modify this refund policy at any time without prior notice. Please review it periodically. Your continued use of our products and services after any changes signifies your acceptance of these changes.</p>
            
            <h3>6. Contact Information</h3>
            <p>For any questions or concerns regarding this policy, or to initiate a refund or cancellation, please contact our customer support team at:</p>
            <ul>
              <li><strong>Email:</strong> contact@vaidhyamegha.com</li>
              <li><strong>Phone:</strong> +91 9618986039</li>
              <li><strong>Addresses:</strong>
                <ul>
                  <li>Spencer Schneier MBR 30 N Gould St STE R Sheridan WY 82801 USA</li>
                  <li>T-hub, 7th Floor, AI & ML20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081</li>
                  <li>3-5-139/3/3/2, Shiva Nagar Colony Hyderguda, Attapur, Hyderabad, Telangana - 500048</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RefundPolicy;