import React, { useState, useEffect } from 'react';
import './reports.css';
import { fetchS3Data } from '../../services/apiService';

const Reports = () => {
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [availableReportTypes, setAvailableReportTypes] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);

  // Fetch initial report types
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const data = await fetchS3Data({
          bucket: 'hwi-csdlc-reports',
        });
        setAvailableReportTypes(data.folders || []);
      } catch (error) {
        /* console.error('Error fetching initial data:', error); */
      }
    };
    fetchInitialData();
  }, []);

  // Fetch years based on selected report type
  useEffect(() => {
    if (selectedReportType) {
      // console.log('Selected Report Type:', selectedReportType);
      const fetchYears = async () => {
        try {
          const data = await fetchS3Data({
            bucket: 'hwi-csdlc-reports',
            mainFolder: selectedReportType,
          });
          // console.log('Fetched Years Data:', data);
          setAvailableYears(data.years || []);
        } catch (error) {
          // console.error('Error fetching years:', error);
        }
      };
      fetchYears();
    }
  }, [selectedReportType]);

  // Log when availableYears is updated
  useEffect(() => {
    // console.log('Available Years:', availableYears);
  }, [availableYears]);

  // Fetch months based on selected year
  useEffect(() => {
    if (selectedReportType && selectedYear) {
      const fetchMonths = async () => {
        try {
          const data = await fetchS3Data({
            bucket: 'hwi-csdlc-reports',
            mainFolder: selectedReportType,
            year: selectedYear,
          });
          setAvailableMonths(data.months || []);
        } catch (error) {
          /* console.error('Error fetching months:', error); */
        }
      };
      fetchMonths();
    }
  }, [selectedReportType, selectedYear]);

  // Log when availableMonths is updated
  useEffect(() => {
    // console.log('Available Months:', availableMonths);
  }, [availableMonths]);

  // Fetch reports based on selected month
  useEffect(() => {
    if (selectedReportType && selectedYear && selectedMonth) {
      const fetchReports = async () => {
        try {
          const data = await fetchS3Data({
            bucket: 'hwi-csdlc-reports',
            mainFolder: selectedReportType,
            year: selectedYear,
            month: selectedMonth,
          });
          setFilteredReports(data.files || []);
        } catch (error) {
          /* console.error('Error fetching reports:', error); */
        }
      };
      fetchReports();
    }
  }, [selectedReportType, selectedYear, selectedMonth]);

  // Log when filteredReports is updated
  useEffect(() => {
    // console.log('Filtered Reports:', filteredReports);
  }, [filteredReports]);

  const handleReportTypeChange = (event) => {
    const reportType = event.target.value;
    // console.log('Report Type Changed:', reportType);
    setSelectedReportType(reportType);
    setSelectedYear('');
    setSelectedMonth('');
    setAvailableYears([]);
    setAvailableMonths([]);
    setFilteredReports([]);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    // console.log('Year Changed:', year);
    setSelectedYear(year);
    setSelectedMonth('');
    setAvailableMonths([]);
    setFilteredReports([]);
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    // console.log('Month Changed:', month);
    setSelectedMonth(month);
    setFilteredReports([]);
  };

  const handleViewDocument = (url) => {
    window.open(url, '_blank');
  };

  const clearFilters = () => {
    setSelectedReportType('');
    setSelectedYear('');
    setSelectedMonth('');
    setAvailableYears([]);
    setAvailableMonths([]);
    setFilteredReports([]);
  };

  // Assuming `filteredReports` is an array of URLs
  const getFileName = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0]; // Get the file name without query params
  };

  return (
    <div className='reports-page'>
      <div className="reports-header">
        <div className="header-overlay"></div>
        <div className="header-content">
          <h2 className='screen-title'>Clinical Trials Reports</h2>
          <p>Access and download clinical trial reports organized by type, year, and month</p>
        </div>
      </div>

      <div className="reports-container">
        <div className="filter-section">
          <div className="filter-header">
            <i className="fas fa-filter" aria-hidden="true"></i>
            <h3 className='filter-title'>Filter Reports</h3>
          </div>
          
          <div className="filter-grid">
            <div className="filter-item">
              <label className="rp-form-label" htmlFor="report-type">
                Report Type :
              </label>
              <select 
                id="report-type"
                className="rp-form-select dropdown-arrow-rp"
                value={selectedReportType} 
                onChange={handleReportTypeChange}
                aria-label="Select report type"
              >
                <option value="">Select Report Type</option>
                {availableReportTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>

            {selectedReportType && availableYears.length > 0 && (
              <div className="filter-item">
                <label htmlFor="year">Year</label>
                <select 
                  id="year"
                  value={selectedYear}
                  onChange={handleYearChange}
                  className="rp-form-select dropdown-arrow-rp"
                  aria-label="Select year"
                >
                  <option value="">Select Year</option>
                  {availableYears.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
            )}

            {selectedYear && availableMonths.length > 0 && (
              <div className="filter-item">
                <label htmlFor="month">Month</label>
                <select
                  id="month"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="rp-form-select dropdown-arrow-rp"
                  aria-label="Select month"
                >
                  <option value="">Select Month</option>
                  {availableMonths.map((month) => (
                    <option key={month} value={month}>{month}</option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <button 
            onClick={clearFilters} 
            className="clear-button"
            aria-label="Clear all filters"
          >
            <i className="fas fa-times" aria-hidden="true"></i> Clear Filters
          </button>
        </div>

        {filteredReports.length > 0 && (
          <div className="report-list" role="list">
            {filteredReports.map((url) => (
              <div key={url} className="report-item" role="listitem">
                <div className="report-info">
                  <i className="fas fa-file-pdf" aria-hidden="true"></i>
                  <span>{getFileName(url)}</span>
                </div>
                <button 
                  onClick={() => handleViewDocument(url)}
                  aria-label={`View ${getFileName(url)}`}
                >
                  <i className="fas fa-eye" aria-hidden="true"></i> View
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reports;
