import React from 'react';
import './terms&conditions.css';

const TermsAndConditions = () => {
  return (
    <div className="privacy-policy">
      <section className="tc-banner-wrapper">
        <div className="tc-banner">
          <img
            className="tc-banner-child data-cloud-migrator-label1"
            loading="lazy"
            alt="terms and conditions banner"
            src="/privacy_policy/t&c.png"
          />
          <button className="navigating-terms-and-condition-wrapper">
            <div className="navigating-terms-and">
              Terms and Conditions
            </div>
          </button>
          <h1 className="terms-and-conditions-container">
            Terms and Conditions
          </h1>
        </div>
      </section>

      <div className="privacy-policy-inner">
        <div className="privacy-frame-container">
          <div className="privacy-frame-div">
            <h2>Terms and Conditions</h2>
            <p><strong>Last Updated: December 24, 2024</strong></p>

            <h3>Agreement to Legal Terms</h3>
            <p>
              These Terms and Conditions ("Terms") govern your access to and use of the services offered by VaidhyaMegha Private Limited ("Company," "we," "us," "our"), a company registered in India at the address: 3-5-139/3/3/2, Shiva Nagar Colony, Siva Nagar, Hyderguda, Hyderabad, Telangana 500048. 
            </p>

            <p>In addition to our registered office, we also maintain a presence at the following locations:</p>
            <ul>
              <li>Vaidhyamegha Pvt. Ltd: T-Hub, 7th Floor, AI & ML, 20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081.</li>
              <li>VaidhyaMegha LLC: Virtual office at Spencer Schneier MBR, 30 N Gould St, STE R, Sheridan, WY 82801, USA.</li>
            </ul>

            <p>
              Please note that while the USA location serves as a virtual office, by using our website, https://www.vaidhyamegha.com ("Site"), and any related products, applications, or services (collectively, "Services"), you confirm that you have read, understood, and agreed to these Terms.
            </p>

            <p>You may contact us at:</p>
            <ul>
              <li>Phone: +91 9618986039</li>
              <li>Email: contact@vaidhyamegha.com</li>
              <li>Address: 3-5-139/3/3/2, Shiva Nagar Colony, Siva Nagar, Hyderguda, Hyderabad, Telangana 500048, India</li>
            </ul>

            <p>
              These Terms are legally binding and apply to all users, whether using the Services on behalf of a business or individually. If we revise these Terms, we will provide notice of such changes via email or on our website at least 15 days prior to the effective date of the revised Terms. Continued use of our Services following such changes indicates acceptance of the updated Terms.
            </p>

            <h3>Table of Contents</h3>
            <ol>
              <li>Our Services</li>
              <li>Intellectual Property Rights</li>
              <li>User Eligibility and Representations</li>
              <li>Account Registration</li>
              <li>Purchases and Payment Terms</li>
              <li>Subscriptions and Automatic Renewals</li>
              <li>Restricted and Prohibited Activities</li>
              <li>User-Generated Content</li>
              <li>License to Your Content</li>
              <li>Links to Third-Party Sites and Content</li>
              <li>Privacy Policy</li>
              <li>Term, Termination, and Suspension</li>
              <li>Disclaimers</li>
              <li>Limitation of Liability</li>
              <li>Indemnification</li>
              <li>User Data Responsibility</li>
              <li>Electronic Communications</li>
              <li>Governing Law and Dispute Resolution</li>
              <li>Entire Agreement and Severability</li>
              <li>Contact Information</li>
            </ol>

            <h3>1. Our Services</h3>
            <p>
              Our Services include healthcare data analytics, cloud-based patient management solutions, and other related offerings, providing value-added data solutions and analytics for users within the healthcare industry. Our Services are designed solely for authorized and lawful use, and any use contrary to applicable laws, including but not limited to HIPAA, GDPR, or FISMA standards, is strictly prohibited.
            </p>
            <p>
              International Access: Users accessing our Services from outside India are responsible for compliance with local laws. We disclaim any liability for failure to comply with laws applicable in jurisdictions other than India.
            </p>

            <h3>2. Intellectual Property Rights</h3>
            <p>
              Ownership: VaidhyaMegha Private Limited retains all rights, title, and interest in our Services, including source code, trademarks, text, design, and all proprietary elements (collectively "Content" and "Marks"). Unauthorized copying, distribution, or reproduction of any part of the Content or Marks is strictly prohibited. Any breach may result in immediate suspension of your access, legal action under applicable laws, or both, including claims for damages. Disputes arising from intellectual property violations will be governed by the laws of India, with exclusive jurisdiction in Hyderabad courts.
            </p>
            <p>
              Limited License: We grant you a limited, non-transferable, non-sublicensable, revocable license to use our Services solely for personal, non-commercial purposes. You agree not to resell, redistribute, or otherwise use our intellectual property without our express permission.
            </p>
            <p>
              Breach and Legal Recourse: Unauthorized use of our Content or Marks may result in account termination, immediate cessation of your rights, and legal action for damages or injunctive relief.
            </p>

            <h3>3. User Eligibility and Representations</h3>
            <p>By using our Services, you agree and confirm that:</p>
            <ol>
              <li>You are legally capable of entering binding agreements and are at least 18 years old.</li>
              <li>You provide accurate, complete, and current information for all aspects of registration.</li>
              <li>You will not use our Services for unlawful activities or misuse.</li>
            </ol>
            <p>Violation of these eligibility requirements may result in suspension, legal action, or removal of access to the Services.</p>

            <h3>4. Account Registration</h3>
            <p>To access and use certain features, you are required to register an account. You agree to:</p>
            <ol>
              <li>Maintain the confidentiality of your password and account information.</li>
              <li>Be responsible for activities conducted under your account.</li>
              <li>Inform us immediately of any unauthorized use of your account.</li>
            </ol>
            <p>We reserve the right to terminate or reclaim usernames that violate our policies or are deemed inappropriate.</p>

            <h3>5. Purchases and Payment Terms</h3>
            <p>Accepted Payment Methods: We accept payments via Razorpay payment gateway.</p>
            <p>Pricing: You agree to pay all fees and taxes associated with your purchase of Services. We may adjust prices or correct errors, even after payment has been received.</p>
            <p>Refund Policy: Unless otherwise specified by law, all purchases are non-refundable.</p>

            <h3>6. Subscriptions and Automatic Renewals</h3>
            <p>Subscriptions: Certain Services require subscriptions which renew automatically. By signing up, you consent to these terms.</p>
            <p>Automatic Renewal: Subscription fees will renew using your selected payment method unless canceled. Fees are subject to change, and we will notify you in advance.</p>
            <p>Cancellation: To cancel, please do so in your account settings. Refunds are not provided for cancellations that take effect after the billing cycle.</p>

            <h3>7. Restricted and Prohibited Activities</h3>
            <p>You agree to refrain from:</p>
            <ol>
              <li>Circumventing security or breaching access controls.</li>
              <li>Using bots, scripts, or any automation not authorized by us.</li>
              <li>Conducting any activity that could harm other users, the Company, or any external parties.</li>
            </ol>
            <p>Breaching these restrictions may lead to suspension, legal action, or reporting to law enforcement.</p>

            <h3>8. User-Generated Content</h3>
            <p>Our Services may allow users to post or share content. By submitting content, you:</p>
            <ol>
              <li>Confirm ownership and responsibility for content accuracy.</li>
              <li>Grant us a license to use, reproduce, and distribute your content.</li>
            </ol>
            <p>Content that violates our Terms or is illegal may be removed.</p>

            <h3>9. License to Your Content</h3>
            <p>Feedback and Submissions: Any feedback, ideas, or suggestions you submit to us can be used without compensation. You waive any rights to claims or ownership for content provided to improve our Services.</p>

            <h3>10. Links to Third-Party Sites and Content</h3>
            <p>Our Services may link to third-party sites. We are not liable for third-party content, privacy practices, or external transactions. We advise you to review their policies before use.</p>
            <h3>11. Privacy Policy</h3>
            <p>Your data privacy is governed by our Privacy Policy, which is accessible on our Site. By using our Services, you consent to data handling as outlined in our policy.</p>

            <h3>12. Term, Termination, and Suspension</h3>
            <p>These Terms remain effective until terminated by either party. We reserve the right to terminate your account at our discretion for any violation of these Terms or other lawful reasons.</p>
            <p>Upon termination, your rights under these Terms will cease.</p>

            <h3>13. Disclaimers</h3>
            <p>The Services are provided "AS-IS" without warranties of any kind. We disclaim all warranties, including merchantability, fitness for a particular purpose, and non-infringement.</p>

            <h3>14. Limitation of Liability</h3>
            <p>We are not liable for any indirect, incidental, consequential, or special damages arising from the use of our Services. Our liability is limited to the amount you paid us in the preceding six months.</p>

            <h3>15. Indemnification</h3>
            <p>You agree to indemnify, defend, and hold harmless VaidhyaMegha Private Limited from claims, damages, and liabilities arising from your use of the Services or violation of these Terms.</p>

            <h3>16. User Data Responsibility</h3>
            <p>Although we strive to maintain data security, you are responsible for data backups. We are not liable for data loss or corruption.</p>

            <h3>17. Electronic Communications</h3>
            <p>You consent to electronic communications and agree to the electronic processing of records in relation to our Services.</p>
            <h3>18. Governing Law and Dispute Resolution</h3>
            <p>These Terms are governed by the laws of India. Any disputes arising shall be exclusively resolved in the courts located in Hyderabad, India.</p>
            <h3>19. Entire Agreement and Severability</h3>
            <p>These Terms represent the complete agreement between you and VaidhyaMegha Private Limited. If any provision is deemed invalid, the remaining provisions will remain enforceable.</p>
            <h3>20. Contact Information</h3>
            <p>
              For any questions regarding these Terms, please contact us at:
              VaidhyaMegha Private Limited, 3-5-139/3/3/2, Shiva Nagar Colony, Siva Nagar, Hyderguda, Hyderabad, Telangana 500048
              Phone: +91 9618986039, Email: contact@vaidhyamegha.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;