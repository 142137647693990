import React from 'react';
import './privacy-policy.css'; // Importing the CSS file
//import FrameComponent from '../../components/FrameComponent';

const PrivacyPolicy = () => {
  return (
    // <FrameComponent>
      <div className="privacy-policy">
        <section className="privacy-banner-wrapper">
          <div className="privacy-banner">
            <img
              className="data-cloud-migrator-label"
              loading="lazy"
              alt="privacy policy banner"
              src="/privacy_policy/privacy-policy-header.png"
            />
            <button className="bio-s-d-l-c-tel-label">
              <div className="our-commitment-to">
                Our Commitment to Transparency and Security
              </div>
            </button>
            <h1 className="privacy-policy-for-container">
              <p className="privacy-policy1">Privacy Policy</p>
              <p className="for-vaidhyamegha-private">
                for VaidhyaMegha Private Limited
              </p>
            </h1>
          </div>
        </section>
        <section className="privacy-policy-inner">
          <div className="privacy-frame-container">
            <div className="privacy-frame-div">
              <h2>Privacy Policy</h2>
              <p><strong>Last Updated:</strong> December 24, 2024</p>
              
              <p>VaidhyaMegha Private Limited ("Company," "we," "us," or "our") is committed to protecting your personal information and your right to privacy. This Privacy Policy describes how we collect, use, store, and disclose information about you when you access or use our website <a href="http://www.vaidhyamegha.com">http://www.vaidhyamegha.com</a> (the "Site") and related services (collectively, "Services"). By using our Services, you consent to the practices outlined in this Privacy Policy, applicable to users in India, the United States, and other jurisdictions where our Services are accessed.</p>

              <h3>Contact Information</h3>
              <p>For any questions or concerns regarding this Privacy Policy, or to exercise your privacy rights, please contact us at:</p>
              <ul><li><strong>Email:</strong> contact@vaidhyamegha.com</li>
                <li><strong>Phone:</strong> +91 9618986039</li>
                <li><strong>Addresses:</strong><ul>
                    <li>Spencer Schneier MBR 30 N Gould St STE R Sheridan WY 82801 USA</li>
                    <li>T-hub, 7th Floor, AI & ML20, Inorbit Mall Rd, Vittal Rao Nagar, Madhapur, Hyderabad, Telangana 500081</li>
                    <li>3-5-139/3/3/2, Shiva Nagar Colony Hyderguda, Attapur, Hyderabad, Telangana - 500048</li>
                  </ul>
                </li> </ul>
             

              <h3>Table of Contents</h3>
              <ol>
                <li>Information We Collect</li>
                <li>How We Use Your Information</li>
                <li>Legal Bases for Processing (Applicable to Users in India and US)</li>
                <li>Sharing and Disclosure of Information</li>
                <li>Data Retention</li>
                <li>Security of Your Information</li>
                <li>Cookies and Tracking Technologies</li>
                <li>Your Privacy Rights (India & US Specific)</li>
                <li>Third-Party Websites and Services</li>
                <li>Policy Updates</li>
                <li>Children's Privacy</li>
                <li>International Data Transfers</li>
                <li>Additional Notices for California Residents</li>
                <li>Contact Us</li>
              </ol>

              <h3>1. Information We Collect</h3>
              <p>We collect various types of information to provide and improve our Services:</p>
              <ul><li><strong>Personal Information:</strong> This includes information such as your name, email address, contact number, billing information, and other identifiers provided during registration or transactions.</li>
              <li><strong>Usage Data:</strong> Automatically collected information about how you interact with our Services, including IP address, browser type, and pages viewed, which helps us analyze and enhance user experience.</li>
              <li><strong>Cookies and Tracking Data:</strong> Small data files stored on your device used to remember preferences and for analytics.</li>
              </ul>
              <p>Data collection practices are carried out with consent as required by law, and limited to the minimum information necessary to fulfill specified purposes.</p>
              
              <h3>2. How We Use Your Information</h3>
              <p>We use your information for the following purposes:</p>
              <ol><li>To provide, operate, and maintain our Services.</li>
                <li>To communicate with you regarding updates, offers, and customer support.</li>
                <li>To process payments and fulfill transactions.</li>
                <li>To conduct research and analysis to improve our Services.</li>
                <li>To enforce our Terms, comply with applicable laws, and protect our legal rights.</li></ol>
              <p>We only use your information for lawful purposes and disclose information only when necessary to provide services or comply with legal requirements.</p>

              <h3>3. Legal Bases for Processing (Applicable to Users in India and US)</h3>
              <p>For users in India and the United States, we process your personal information based on the following legal grounds:</p>
              <ol>
                <li><strong>Consent:</strong> Where you have given explicit consent for processing (e.g., for marketing communications).</li>
                <li><strong>Contractual Necessity:</strong> To fulfill contractual obligations when you use our Services.</li>
                <li><strong>Legal Compliance:</strong> To comply with laws and regulations applicable in India and the United States.</li>
                <li><strong>Legitimate Interests:</strong> For purposes such as improving our Services, fraud prevention, and business continuity.</li>
              </ol>
              <p>We ensure transparency and respect your rights to withdraw consent where applicable.</p>

              <h3>4. Sharing and Disclosure of Information</h3>
              <p>We may share your information with third parties in the following scenarios:</p>
                <li><strong>Service Providers:</strong> Third-party vendors who assist us in providing Services, including payment processors, hosting providers, and analytics services.</li>
                <li><strong>Legal Obligations:</strong> In response to legal processes or to comply with applicable laws and regulatory requirements.</li>
                <li><strong>Business Transfers:</strong> In cases of mergers, acquisitions, or asset sales, your data may be transferred to the acquiring entity.</li>
              <p>Third parties are contractually required to maintain confidentiality and only use data as directed by us.</p>

              <h3>5. Data Retention</h3>
              <p>We retain personal data only as long as necessary to fulfill the purposes outlined in this Privacy Policy. Retention periods are determined based on business needs, legal requirements, and industry standards. Once data is no longer required, it is securely deleted or anonymized.</p>

              <h3>6. Security of Your Information</h3>
              <p>We implement industry-standard security measures to protect your data from unauthorized access and disclosure. Despite our efforts, no security measure is completely infallible. Users are encouraged to use strong passwords and maintain their account security to further protect their data.</p>

              <h3>7. Cookies and Tracking Technologies</h3>
              <p>We use cookies and similar tracking technologies to collect usage data, remember user preferences, and enhance your experience on our Site. You may choose to disable cookies via your browser settings, though this may affect certain features of the Services.</p>

              <h3>8. Your Privacy Rights (India & US Specific)</h3>
              <p>Depending on your location, you may have specific rights related to your personal data:</p>
              
              <h4>For Users in India:</h4>
              <ul>
                <li><strong>Access and Correction:</strong> Request access to or correction of your personal data.</li>
                <li><strong>Deletion:</strong> Request the deletion of your data under certain circumstances.</li>
                <li><strong>Data Portability:</strong> Request a copy of your data in a portable format.</li>
              </ul>

              <h4>For Users in the United States:</h4>
              <ul>
                <li><strong>Access:</strong> Request access to specific pieces of information collected.</li>
                <li><strong>Deletion:</strong> Request deletion of personal information.</li>
                <li><strong>Opt-Out of Sale:</strong> California residents may opt out of the "sale" of their personal data.</li>
              </ul>

              <p>To exercise any of these rights, please contact us as outlined in the "Contact Information" section.</p>

              <h3>9. Third-Party Websites and Services</h3>
              <p>Our Services may contain links to third-party sites. We are not responsible for the privacy practices of third parties and recommend reviewing their privacy policies before use.</p>

              <h3>10. Policy Updates</h3>
              <p>We reserve the right to update this Privacy Policy as required. Significant changes will be communicated via email or by posting on our Site. Your continued use of the Services indicates acceptance of the updated policy.</p>

              <h3>11. Children's Privacy</h3>
              <p>Our Services are intended for users over the age of 13. We do not knowingly collect personal data from children. If we become aware that a child's data has been collected, we will delete it promptly.</p>

              <h3>12. International Data Transfers</h3>
              <p>Your information may be transferred and processed in countries other than your own. By using our Services, you consent to this transfer of data to India, the United States, or other jurisdictions where our service providers operate.</p>

              <h3>13. Additional Notices for California Residents</h3>
              <p>Under the California Consumer Privacy Act (CCPA), California residents have the right to request access, deletion, and information about the data we collect. To exercise these rights, please contact us as outlined in this Privacy Policy.</p>

              <h3>14. Contact Us</h3>
              <p>For any questions, concerns, or to exercise your privacy rights, please contact us at:</p>
              <address>
                VaidhyaMegha Private Limited<br />
                3-5-139/3/3/2, Shiva Nagar Colony, Siva Nagar, Hyderguda, Hyderabad, Telangana 500048, India<br />
                Phone: +91 9618986039<br />
                Email: contact@vaidhyamegha.com
              </address>
            </div>
          </div>
        </section>
      </div>
    // </FrameComponent>
  );
}

export default PrivacyPolicy;